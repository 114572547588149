import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>

        <div>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div 
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem`,
            borderRadius: 10,
            backgroundColor: '#f9f9f9'
          }}
        >
          <main style={{width:'80%'}}>{children}</main>
          <footer>
            © {new Date().getFullYear()} SCGough Ltd. <OutboundLink 
            href="/privacy" style={{backgroundColor: 'transparent', color: 'hsla(0, 0%, 0%, 0.8)'}}>Privacy Policy</OutboundLink> 
          </footer>
        </div>
        </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
