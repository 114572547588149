import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "../components/layout.css"

const Header = ({ siteTitle }) => (
  <header
    style={{
      margin: `100px auto 0 auto`,
      maxWidth: 960,
      padding: `0 1.0875rem 1.45rem`,
    }}
  >
    <h1 className="mainHeading"><div className="logo"></div> Hi, I'm Simon</h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
